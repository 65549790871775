import React, { useEffect } from 'react';
import cn from 'classnames';
import { useRouter } from 'next/router';
import Head from 'next/head';

import SignIn from 'components/candidate/SignIn';
import Logo from 'components/Logo';
import { PAGE_ROUTES } from 'lib/page-routes';
import { useAuth } from 'lib/providers/AuthProvider';

import styles from './Signin.module.scss';

const Signin: React.FC = () => {
  const router = useRouter();
  const { isCandidate, isTemporaryCandidate, isInitialAuthLoading } = useAuth();

  useEffect(() => {
    if (isCandidate && !isTemporaryCandidate) {
      router.replace(PAGE_ROUTES.CANDIDATE_MATCHES);
    }
  }, [isCandidate, isTemporaryCandidate]);

  const onSignInComplete = () => {
    router.replace(PAGE_ROUTES.CANDIDATE_MATCHES);
  };

  if (isInitialAuthLoading || isCandidate || isTemporaryCandidate) return null;

  return (
    <>
      <Head>
        <title>Student Sign Up & Login | Huzzle</title>
        <meta
          name="description"
          content="Sign in or sign up to Huzzle as a Student, with just an email."
        />
      </Head>
      <section className={styles.container} data-testid="Signin">
        <div className={styles.gradient1}></div>
        <Logo
          name="huzzle-new"
          size="small"
          color="primary"
          className={cn(styles.logo, styles.hideMobile)}
          onClick={() => router.push(`${process.env.NEXT_PUBLIC_SITE_URL}`)}
        />
        <SignIn
          onComplete={onSignInComplete}
          isLoginPopup={false}
          className={styles.signin}
        />
        <div className={styles.gradient2}></div>
        <div className={styles.gradient3}></div>
      </section>
    </>
  );
};

export default Signin;
